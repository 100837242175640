<template lang="pug">
  form-wrapper(:validator="$v.user")
    form.new(@keyup.enter="createUser")
      app-panel
        app-text-field(class="w-1/2", label="Name", name="name", v-model="user.name")
        app-text-field(class="w-1/2", label="Email", name="email",  v-model="user.email", type="email")

      app-button(primary, @click.prevent="createUser") Add user
      app-button.ml-4(@click.prevent="$router.push('/users')") Cancel
</template>

<script>
import CreateSuperadminUserMutation from "@/graphql/mutations/superadmin_users/CreateSuperadminUserMutation.gql";
import { required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      user: {
        name: null,
        email: null,
      },
      isLoading: false
    };
  },
  methods: {
    createUser() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if(!this.isLoading) {
        this.isLoading = true;
        return this.$apollo
        .mutate({
          mutation: CreateSuperadminUserMutation,
          variables: {
            input: {
              ...this.user,
            },
          },
        })
        .then(() => {
          this.$flash.success(`${this.user.name} was successfully invited`);
          this.$router.push("/users");
        })
        .catch(() => {
          this.$flash.error("It was not possible to create a new user");
        });
      }
    },
  },
  validations: {
    user: {
      name: {
        required,
      },
      email: {
        required,
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
.panel {
  @apply bg-white;
  @apply shadow-lg;
  @apply p-8;
  @apply mb-8;
}
</style>
